export enum EEX_PATHS {
  DISCORD = "https://discord.gg/discosolaris",
  FORUM = "https://forum.discosolaris.com",
  LEARN_MORE_WALLETS = "https://docs.cardano.org/new-to-cardano/types-of-wallets",
  LORE = "https://lore.discosolaris.com/ds/",
  DISCLAIMER = "https://discosolaris.com/documents/DiscoSolaris_Disclaimer.pdf",
  PRIVACY = "https://discosolaris.com/documents/DiscoSolaris_PrivacyPolicy.pdf",
  TERMS = "https://discosolaris.com/documents/DiscoSolaris_Terms.pdf",
  TWITTER = "https://twitter.com/discosolaris",
  INSTAGRAM = "https://www.instagram.com/discosolaris/",
  PURCHASE = "https://jpg.store/collection/discosolaris",
}
