import { AppProvider, i18Init, namespaces } from "@sundae/gov-toolkit";
import ReactDOM from "react-dom/client";
import { useI18N } from "@sundae/react-hooks";
import resourcesToBackend from "i18next-resources-to-backend";

import "./tailwind.scss";
import { EEX_PATHS } from "./types";
import { useMemo } from "react";

(async () => {
  i18Init(
    (language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`).then(({ default: resources }) =>
        callback(null, resources)
      );
    },
    (options) => {
      options.ns = [...namespaces, "discoin"];
      options.debug = false;
      return options;
    }
  );
})();

const App = () => {
  const { t, i18n } = useI18N("discoin");
  i18n.use(
    resourcesToBackend((language, namespace, callback) => {
      const path = `./translations/${language}/${namespace}.json`;
      import(path).then(({ default: resources }) => callback(null, resources));
    })
  );

  const navProps = useMemo(() => {
    return {
      className: "!text-black dark:!text-white hover:opacity-75 transition-opacity",
      getActiveProps: () => ({
        className: "!underline",
      }),
    };
  }, []);

  return (
    <AppProvider
      bgImage="/static/images/bg-static.jpeg"
      componentProps={{
        header: {
          navigationItems: [
            {
              to: "/",
              title: t("navigation.home"),
              ...navProps,
            },
            {
              to: "/create",
              title: t("navigation.create"),
              ...navProps,
            },
            {
              to: EEX_PATHS.LORE,
              title: t("navigation.lore"),
              external: true,
              target: "_blank",
            },
            {
              to: EEX_PATHS.PURCHASE,
              title: t("navigation.purchase"),
              external: true,
              target: "_blank",
            },
            {
              dropdown: {
                menuTitle: t("navigation.more"),
                menuContent: [
                  {
                    to: EEX_PATHS.TWITTER,
                    title: t("navigation.twitter"),
                    description: t("navigation.twitter_desc"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.DISCORD,
                    title: t("navigation.discord"),
                    description: t("navigation.discord_desc"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.INSTAGRAM,
                    title: t("navigation.instagram"),
                    description: t("navigation.instagram_desc"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.FORUM,
                    title: t("navigation.forum"),
                    description: t("navigation.forum_desc"),
                    external: true,
                  },
                ],
              },
            },
          ],
          renderLogo: () => (
            <div className="text-black dark:text-white">
              <img src="/static/images/DISCOIN-Nav.Logo.png" className="w-32 dark:hidden" />
              <img
                src="/static/images/DISCOIN-Nav.Logo.dark.png"
                className="hidden w-32 dark:block"
              />
            </div>
          ),
        },
      }}
      brand={{
        title: t("brand.title"),
        description: t("brand.description"),
        classes: {
          buttons: {
            all: "!text-black",
            secondary: "!text-black dark:!text-white",
            tabs: "!text-black dark:!text-white",
            colored: "",
            primary: "",
          },
          text: {
            title: "!text-white",
            description: "!text-white",
          },
        },
        images: {
          audit_pending: "/static/images/Discoin.Results-Pending@2x - chris mena.png",
          casting_a_vote: "/static/images/Discoin.Success-Cast-Vote@2x - chris mena.png",
          fatal_error: "/static/images/Discoin.Fatal-Error@2x - chris mena.png",
          not_enough_tokens: "/static/images/Discoin.Not-Allowed@2x - chris mena.png",
          proposal_created: "/static/images/Discoin.Success@2x - chris mena.png",
        },
      }}
    />
  );
};

const target = document.querySelector("#app");
const root = target && ReactDOM.createRoot(target);
if (root) {
  root.render(<App />);
}
